import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { withTrans } from "../i18n/withTrans"

const NotFoundPage = ({t, ...props}) => (
  <Layout>
    <Seo title="404: Not found" />
    <main id="maincontent">
      <div style={{height: "50vh", paddingTop: "30vh", paddingBottom: "20vh", paddingLeft:"30vw", paddingRight:"30vw"}}>
          <h1>Error 404</h1>
          <p className="p11G">{t("404")}</p>
      </div>
    </main>
  </Layout>
)

export default withTrans(NotFoundPage)
